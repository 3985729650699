<template>
  <div class="auth-form-wrapper">
    <form @submit.prevent="getLogin">
      <div class="form-group fieldsErrorMessage">
        {{ AUTH_ERROR }}
      </div>
      <div class="form-group">
        <input
          type="text"
          placeholder="логин"
          v-model="login"
          @keypress="errorEmpty"
          class="form-control"
        />
        <small
          v-if="
            ($v.login.$dirty && !$v.login.required) ||
              ($v.login.$dirty && !$v.login.minLength)
          "
          class="form-text text-muted"
          >введите логин</small
        >
        <div v-else />
      </div>
      <div class="form-group">
        <input
          type="password"
          placeholder="пароль"
          autocomplete="false"
          v-model="password"
          @keypress="errorEmpty"
          class="form-control"
        />
        <small
          v-if="
            ($v.password.$dirty && !$v.password.required) ||
              ($v.password.$dirty && !$v.password.minLength)
          "
          class="form-text text-muted"
          >введите пароль</small
        >
        <div v-else />
      </div>
      <button class="btn btn-primary btn-block">
        войти
      </button>
    </form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "Login",
  data: () => ({
    login: "",
    password: "",
    error: null
  }),
  validations: {
    login: { required, minLength: minLength(3) },
    password: { required, minLength: minLength(3) }
  },
  computed: {
    ...mapGetters(["AUTH_ERROR"])
  },
  methods: {
    ...mapActions(["LOGIN"]),
    getLogin() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      const login = this.login;
      const password = this.password;
      this.LOGIN({ login, password }).then(() => this.$router.push("/"));
    },
    errorEmpty() {
      if (this.error) {
        this.error = null;
      }
    }
  }
};
</script>
<style scoped>
.auth-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.fieldsErrorMessage {
  text-align: center;
  color: red;
}
</style>
